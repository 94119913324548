import React, { useState, useEffect, useRef } from "react";
import NavBar from "../NavBar.js";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFileExport,
  faPrint,
  faInfoCircle,
  faTrashAlt,
  faHouse,
  faPowerOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";
import NewPurchasesScheme from "./newSchemePurchase.js";
import ReferenceDetails from "./ModalReferenceDetails.js";

export default function SchemPurchase() {
  const [showModal, setShowModal] = useState(false);
  const [showRefModal, setShowRefModal] = useState(false);
  const [searchRef, setSearchRef] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [scheme, setScheme] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    customerName: "",
    referenceId: "",
    customerMobile: "",
  });

  useEffect(() => {
    fetchSchmeName();
  }, []);

  const fetchSchmeName = async () => {
    try {
      const response = await fetch("https://www.empairindia.com/svv/getAllCustomers");
      if (!response.ok) {
        throw new Error("Failed to fetch suppliers");
      }
      const data = await response.json();
      setProductsData(data.productsData);
      setScheme(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleRefModalClose = () => {
    setShowRefModal(false);
  };
  const handleSaveValue = (value) => {
    setTableData([...tableData, value]);
  };

  const searchReferenceId = (referenceId) => {
    const minLength = 4;
    const matches = scheme.flatMap((scheme) =>
      scheme.customerData.flatMap((customer) =>
        customer.reference.filter(
          (ref) =>
            ref.referenceId === referenceId ||
            (ref.referenceId.startsWith(referenceId.slice(0, -1)) &&
              ref.referenceId.length === referenceId.length - 1)
        )
      )
    );
    setSearchRef(matches);

    if (referenceId.length > minLength) {
      searchReferenceId(referenceId.slice(0, -1));
    }
  };

const handleReferenceIdClick = (referenceId, customer) => {
    // Set the selected customer details in one state
    setSelectedCustomer({
      customerName: customer.customerName,
      referenceId: customer.referenceId,
      customerMobile: customer.customerMobile,
      referenceAmount: customer.referenceAmount
    });
  
    setShowRefModal(true); // Open the modal
  
    const minLength = 4;
  
    // Define the amounts for each level (L1 = 500, L2 = 400, etc.)
    const lengthToAmountMap = {
      1: 500,
      2: 125,
      3: 75,
      4: 25,
      5: 15,
      6: 5,
      7: 5,
      8: 5,
      9: 5,
      10: 5,
      11: 5,
      12: 5,
      13: 5,
      14: 5
    };
  
    const searchReferenceId = (id, length = 1) => {
      if (id.length < minLength) return;
  
      const matches = scheme.flatMap((schemeItem) =>
        schemeItem.customerData.filter(
          (customer) =>
            customer.referenceId === id ||
            (customer.referenceId.startsWith(id.slice(0, -1)) &&
              customer.referenceId.length === id.length - 1)
        )
      );
  
      setSearchRef((prevResults) => {
        const uniqueMatches = matches.filter(
          (newMatch) =>
            !prevResults.some(
              (existing) => existing.referenceId === newMatch.referenceId
            )
        );
  
        // Temporarily add the Length and corresponding referenceAmount
        const updatedMatches = uniqueMatches.map((match) => ({
          ...match,
          Length: `L${length}`, // Adding the Length field
          referenceAmount: lengthToAmountMap[length] || 100, // Map Length to Amount
        }));
  
        return [...prevResults, ...updatedMatches];
      });
  
      searchReferenceId(id.slice(0, -1), length + 1); // Recursive call with shortened id and increment length
    };
  
    setSearchRef([]); // Clear previous results
    searchReferenceId(referenceId); // Start search
  };  
  

  return (
    <div>
      <nav className="navbar navbar-light ">
        <div className="container-fluid">
          <Link className="navbar-brand ms-5 d-flex align-items-center" to="/">
            <img
              src={logo}
              alt="logo"
              width="100px"
              className="d-inline-block align-text-top"
            />
            <strong className="fs-2 ">EMPAIR MARKETING PVT LTD</strong>
          </Link>
          <div className="d-flex" style={{ width: "600px" }}>
            <FontAwesomeIcon icon={faHouse} className=" me-5 mt-4" />
            <FontAwesomeIcon
              icon={faPowerOff}
              onClick={""}
              className="text-danger me-5 mt-4"
            />
            <div className="d-flex">
              <FontAwesomeIcon icon={faUser} className="me-3 mt-4" />
              <ul className="list-unstyled mb-1" style={{ width: "150px" }}>
                <li className="me-2">Employee</li>
                <li className="me-2">Place</li>
                <li className="me-2">Time</li>
                <li>Date</li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="marquee  px-5 m-2">
        <marquee className="text-white" behavior="scroll" direction="left">
          New Updates : Welcome to EMPAIR MARKETING PVT LTD....Have a nice
          day....{" "}
        </marquee>
      </div>
      <NavBar />
      <div
        className="text-center mt-3"
        style={{ backgroundColor: "lightgrey" }}
      >
        <p>
          <b>Scheme List (Year: 2024-2025)</b>
        </p>
      </div>

      <Container>
        <Row>
          <Col sm={3}>
            <Form.Group controlId="input2">
              <Form.Label>Scheme</Form.Label>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="input4">
              <Form.Label>Added By:</Form.Label>
              <Form.Control size="sm" as="select">
                <option value="">Select an option</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="input2">
              <Form.Label>Date From</Form.Label>
              <Form.Control size="sm" type="date" />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="input3">
              <Form.Label>To</Form.Label>
              <Form.Control size="sm" type="date" />
            </Form.Group>
          </Col>
        </Row>

        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th>SL No</th>
              <th>Customer Name</th>
              <th>Phone Number</th>
              <th>Customer Id</th>
              <th>Invoice Number</th>
              <th>Scheme Name</th>
              <th>Reference Id</th>
              <th>Is Active</th>
            </tr>
          </thead>
          <tbody>
            {scheme.map(
              (schemeItem, index) =>
                schemeItem.customerData &&
                schemeItem.customerData.map((customer, customerIndex) => (
                  <tr
                    key={`${index}-${customerIndex}`}
                    onClick={() =>
                      handleReferenceIdClick(customer.referenceId, customer)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>{customerIndex + 1}</td>
                    <td>{customer.customerName}</td>
                    <td>{customer.customerMobile}</td>
                    <td>{customer.customerId}</td>
                    <td>{customer.invoiceNumber}</td>
                    <td>{schemeItem.SchemeName}</td>
                    <td>{customer.referenceId}</td>
                    <td>{schemeItem.isActive ? "Active" : "Inactive"}</td>
                  </tr>
                ))
            )}
          </tbody>
        </Table>
        <Row className="text-center mt-3">
          <Col xs={2} className="px-1">
            <Button
              variant="success"
              size="sm"
              className="w-50"
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} /> New
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-50">
              <FontAwesomeIcon icon={faInfoCircle} /> Details
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-50">
              <FontAwesomeIcon icon={faFileExport} /> Export
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-50">
              <FontAwesomeIcon icon={faPrint} /> Print
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-80">
              <FontAwesomeIcon icon={faPrint} /> Print Voucher
            </Button>
          </Col>
          <Col xs={{ span: 0, offset: 0 }} className="px-1 w-40">
            <Button variant="secondary" size="sm">
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </Button>
          </Col>
        </Row>
      </Container>

      <NewPurchasesScheme
        show={showModal}
        handleClose={handleModalClose}
        handleSave={handleSaveValue}
      />
      <ReferenceDetails
        show={showRefModal}
        handleClose={handleRefModalClose}
        searchRef={searchRef}
        customerDetails={selectedCustomer} 
      />
    </div>
  );
}

// const handleReferenceIdClick = (referenceId, customerData) => {
//     setShowRefModal(true); // Open the modal

//     const minLength = 4;  // Set the minimum length for the referenceId (e.g., EMPB)

//     const searchReferenceId = (id) => {
//         // Base case: Stop if the referenceId is shorter than the minimum length
//         if (id.length < minLength) {
//             return;
//         }

//         // Search for matches in scheme.customerData
//         const matches = scheme.flatMap(scheme =>
//             scheme.customerData.filter(customer => {
//                 const ref = customer.referenceId;
//                 // Match either the exact referenceId or one that is shorter by one character
//                 return ref === id || (ref.startsWith(id.slice(0, -1)) && ref.length === id.length - 1);
//             })
//         );

//         // Update the state with the matched results
//         setSearchRef(prevResults => {
//             // Filter to avoid duplicate entries in the searchRef array
//             const uniqueMatches = matches.filter(newMatch =>
//                 !prevResults.some(existing => existing.referenceId === newMatch.referenceId)
//             );
//             return [...prevResults, ...uniqueMatches];
//         });

//         // Log the matching referenceIds for debugging
//         console.log(`Matches for "${id}":`, matches);

//         // Recursively call the function with the shortened referenceId
//         searchReferenceId(id.slice(0, -1)); // Remove the last character and search again
//     };

//     // Start the search with the full referenceId
//     setSearchRef([]); // Clear previous results before starting a new search
//     searchReferenceId(referenceId);

//     // Pass the customer data to the modal
//     setTableData(customerData);
// };

//====================
// const handleReferenceIdClick = (referenceId, customerData) => {
//     console.log(`Clicked Reference ID: ${referenceId} for customer:`, customerData);
//     if (!referenceId) {
//         console.error("handleReferenceIdClick called without a referenceId");
//         return;
//     }
//     setShowRefModal(true); // Open the modal

// const minLength = 4;  // Set the minimum length for the referenceId (e.g., EMPB)

// const searchReferenceId = (id) => {
//     console.log(`Searching referenceId: "${id}"`);
//     // Base case: Stop if the referenceId is shorter than the minimum length
//     if (!id || id.length < minLength) {
//         return;
//     }
//     // Search for matches in scheme.customerData's references
//     const matches = scheme.flatMap(schemeItem =>
//         schemeItem.customerData.flatMap(customer =>
//             customer.reference.filter(ref => {
//                 return ref.referenceId === id || (ref.referenceId.startsWith(id.slice(0, -1)) && ref.referenceId.length === id.length - 1);
//             }).map(ref => ({
//                 ...ref,
//                 customerName: customer.customerName // Include customerName for display
//             }))
//         )
//     );

//     console.log(`Found matches:`, matches);

//     // Update the state with the matched results
//     setSearchRef(prevResults => {
//         const uniqueMatches = matches.filter(newMatch =>
//             !prevResults.some(existing => existing.referenceId === newMatch.referenceId)
//         );
//         return [...prevResults, ...uniqueMatches];
//     });

//     // Recursive call
//     searchReferenceId(id.slice(0, -1));
// };

//     setSearchRef([]);
//     searchReferenceId(referenceId);

//     // Pass the customer data to the modal
//     setTableData(customerData);
// };

//=====----------------
// const handleReferenceIdClick = (referenceId, customerData) => {
//     setShowRefModal(true);

//     // We assume that the customerData has a 'reference' array. We'll find the specific reference by ID.
//     const selectedReference = customerData.reference.find(ref => ref.referenceId === referenceId);

//     // const minLength = 4;  // Set the minimum length for the referenceId (e.g., EMPB)

//     const searchReferenceId = (referenceId) => {
//         const minLength = 4;  // Set the minimum length for the referenceId (e.g., EMPB)

//         // const matches = scheme.flatMap(schemeItem =>
//         //     schemeItem.customerData.flatMap(customer =>
//         //         customer.reference.filter(ref =>
//         //             ref.referenceId === referenceId ||
//         //             (ref.referenceId.startsWith(referenceId.slice(0, -1)) && ref.referenceId.length === referenceId.length - 1)
//         //         ).map(ref => ({ ...ref, customerName: customer.customerName })) // Include customerName
//         //     )
//         // );
//         const matches = scheme.flatMap(scheme =>
//             scheme.customerData.filter(customer => {
//                 const ref = customer.referenceId;
//                 // Match either the exact referenceId or one that is shorter by one character
//                 return ref === referenceId || (ref.startsWith(referenceId.slice(0, -1)) && ref.length === referenceId.length - 1);
//             })
//         );

//         // Update the state with the matched results
//         setSearchRef(prevResults => {
//             // Filter to avoid duplicate entries in the searchRef array
//             const uniqueMatches = matches.filter(newMatch =>
//                 !prevResults.some(existing => existing.referenceId === newMatch.referenceId)
//             );
//             return [...prevResults, ...uniqueMatches];
//         });

//         // Recursively call the function with the shortened referenceId
//         if (referenceId.length > minLength) {
//             searchReferenceId(referenceId.slice(0, -1)); // Remove the last character and search again
//         }
//     };

//     // Start the search with the full referenceId
//     setSearchRef([]); // Clear previous results before starting a new search
//     searchReferenceId(referenceId); // Search references

//     // Pass the selected customer data to the modal, including the reference information
//     setTableData(customerData);
// };
  //   const handleReferenceIdClick = (referenceId, customerData) => {
  //     // console.log(
  //     //   "handleReferenceIdClick called with:",
  //     //   referenceId,
  //     //   customerData
  //     // );
  //     setShowRefModal(true); // Open the modal

  //     const minLength = 4; // Set the minimum length for the referenceId (e.g., EMPB)

  //     const searchReferenceId = (id) => {
  //       if (id.length < minLength) return;

  //       const matches = scheme.flatMap((schemeItem) =>
  //         schemeItem.customerData.filter(
  //           (customer) =>
  //             customer.referenceId === id ||
  //             (customer.referenceId.startsWith(id.slice(0, -1)) &&
  //               customer.referenceId.length === id.length - 1)
  //         )
  //       );

  //       setSearchRef((prevResults) => {
  //         const uniqueMatches = matches.filter(
  //           (newMatch) =>
  //             !prevResults.some(
  //               (existing) => existing.referenceId === newMatch.referenceId
  //             )
  //         );
  //         return [...prevResults, ...uniqueMatches];
  //       });

  //     //   console.log(`Matches for "${id}":`, matches);

  //       searchReferenceId(id.slice(0, -1)); // Recursive call with shortened id
  //     };

  //     setSearchRef([]); // Clear previous results
  //     searchReferenceId(referenceId); // Start search

  //     setTableData(customerData); // Pass customer data to modal
  //   };
