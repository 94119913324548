import React from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { Toaster, toast } from 'sonner'

function ModalReferenceDetails({ show, handleClose, searchRef, customerDetails }) {

    const { customerName } = customerDetails;
    console.log(searchRef);
    console.log(customerDetails);
    const filteredReferences = searchRef.filter(ref => ref.customerName !== customerName);

    const otherCustomers = filteredReferences.map(ref => ({
        _id: ref._id, // Assuming ref._id is available
        referenceAmount: ref.referenceAmount || 'N/A', // Include referenceAmount
    }));

    // Handle the button click to send data
    const handleSendData = async () => {
        try {
            // Prepare the data to send
            const dataToSend = {
                customerDetails,
                otherCustomers,
            };

            console.log("Sending data:", JSON.stringify(dataToSend));

            // Send data to the backend
            const response = await fetch("https://www.empairindia.com/svv/sendRefDetails", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            // Show success toast
            toast.success("Data sent successfully!");
            handleClose();
        } catch (error) {
            console.error("Error sending customer details:", error);
            if (error instanceof Error) {
                console.error("Error message:", error.message);
            }

            // Show error toast
            toast.error("Failed to send data. Please try again.");
        }
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{customerName} Reference Details </Modal.Title>

                    <Button
                        variant="primary"
                        onClick={handleSendData}
                    >Send</Button>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr>
                                <th>Sl_No</th>
                                <th>Customer Name</th>
                                <th>Reference Name</th>
                                <th>Reference ID</th>
                                <th>Phone Number</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReferences.length > 0 ? (
                                filteredReferences.map((ref, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{ref.customerName || 'N/A'}</td>
                                        <td>{ref.referenceName || 'N/A'}</td>
                                        <td>{ref.referenceId || 'N/A'}</td>
                                        <td>{ref.referenceMobile || 'N/A'}</td>
                                        <td>{ref.referenceAmount || 'N/A'}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No references available</td>
                                </tr>
                            )}
                        </tbody>

                    </Table>
                </Modal.Body>
            </Modal>
            <Toaster />
        </div>
    );
}

export default ModalReferenceDetails;